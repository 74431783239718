<template>
  <v-container
    v-if="e.mode === 'wk_score4' || e.mode === 'wk_4aus5' || e.mode === 'test'"
    fluid
  >
    <h2>Setze Startmannschaft:</h2>
    <v-data-table
      :items="e.teams"
      :headers="[
        { text: 'Mannschaft', value: 'team.name', sortable: false },
        { text: '', value: 'set' }
      ]"
      hide-default-footer
      hide-default-header
      :mobile-breakpoint="0"
    >
      <template #item.set="{item}">
        <template v-if="item.order === 1">beginnt</template>
        <template v-else>
          <v-btn
            @click="set(item)"
          >
            setze als Startmannschaft
          </v-btn>
        </template>
      </template>
    </v-data-table>
  </v-container>
  <v-container
    v-else-if="e.mode === 'lp_3aus4' || e.mode === 'lf_4aus5' || e.mode === 'lf_4aus4'"
    fluid
  >
    <v-data-table
      :items="e.teams"
      :headers="[
        { text: 'Mannschaft', value: 'team.name', sortable: false },
        ...disc.map(d => ({ text: d.shortName, value: d._id, sortable: false }))
      ]"
      hide-default-footer
      :items-per-page="-1"
      mobile-breakpoint="0"
    >
      <template #item="{item}">
        <tr>
          <td>{{ item.team.name }}</td>
          <td v-for="d in disc" :key="d._id">
            <v-icon v-if="item._startdiscipline === d._id">far fa-check</v-icon>
            <v-btn
              v-else
              text
              fab
              x-small
              @click="setstartdiscipline(item.team._id, d._id)"
            >
              <v-icon color="grey lighten-2">far fa-xmark</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
  <v-container
    v-else
    fluid
  >
    Unbekannter Wettkampfmodus
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'
import { useCalc } from '../../../plugins/calc'

export default {
  name: 'riegen',

  setup (props, context) {
    return {
      ...useGraphQL(context),
      ...useCalc()
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  computed: {
    disc () {
      return (this.df?.map(d => ({
        ...d,
        __typename: undefined,
        active: !!this.e?.disciplines?.find(ed => ed._discipline === d._id),
        order: this.e?.disciplines?.find(ed => ed._discipline === d._id)?.order
      })) || [])
        .filter(d => d.active)
        .sort((a, b) => {
          return a.order < b.order ? -1 : 1
        })
    }
  },

  methods: {
    set (item) {
      this.setorder(item.team._id, 1)
      this.setorder(this.e?.teams?.find(t => t.team?._id !== item.team._id)?.team?._id, 2)
    },
    setorder (team, order) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $team: UUID!, $order: Int!) {
            StbM2021WkTeamUpdateOrder(id: $id, team: $team, order: $order) {
              _id
            }
          }
        `,
        variables: {
          id: this.id,
          team,
          order
        }
      })
    },
    setstartdiscipline (tid, did) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $tid: UUID!, $did: UUID!) {
            StbM2021WkTeamUpdateStartdiscipline(id: $id, team: $tid, startdiscipline: $did) {
              _id
            }
          }
        `,
        variables: {
          id: this.id,
          tid,
          did
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
