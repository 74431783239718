var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.e.mode === 'wk_score4' || _vm.e.mode === 'wk_4aus5' || _vm.e.mode === 'test')?_c('v-container',{attrs:{"fluid":""}},[_c('h2',[_vm._v("Setze Startmannschaft:")]),_c('v-data-table',{attrs:{"items":_vm.e.teams,"headers":[
      { text: 'Mannschaft', value: 'team.name', sortable: false },
      { text: '', value: 'set' }
    ],"hide-default-footer":"","hide-default-header":"","mobile-breakpoint":0},scopedSlots:_vm._u([{key:"item.set",fn:function({item}){return [(item.order === 1)?[_vm._v("beginnt")]:[_c('v-btn',{on:{"click":function($event){return _vm.set(item)}}},[_vm._v(" setze als Startmannschaft ")])]]}}],null,false,1290107153)})],1):(_vm.e.mode === 'lp_3aus4' || _vm.e.mode === 'lf_4aus5' || _vm.e.mode === 'lf_4aus4')?_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"items":_vm.e.teams,"headers":[
      { text: 'Mannschaft', value: 'team.name', sortable: false },
      ..._vm.disc.map(d => ({ text: d.shortName, value: d._id, sortable: false }))
    ],"hide-default-footer":"","items-per-page":-1,"mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item",fn:function({item}){return [_c('tr',[_c('td',[_vm._v(_vm._s(item.team.name))]),_vm._l((_vm.disc),function(d){return _c('td',{key:d._id},[(item._startdiscipline === d._id)?_c('v-icon',[_vm._v("far fa-check")]):_c('v-btn',{attrs:{"text":"","fab":"","x-small":""},on:{"click":function($event){return _vm.setstartdiscipline(item.team._id, d._id)}}},[_c('v-icon',{attrs:{"color":"grey lighten-2"}},[_vm._v("far fa-xmark")])],1)],1)})],2)]}}])})],1):_c('v-container',{attrs:{"fluid":""}},[_vm._v(" Unbekannter Wettkampfmodus ")])
}
var staticRenderFns = []

export { render, staticRenderFns }